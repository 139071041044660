import moment from "./momment";

export function printDates(start, end) {
  const date_start = moment(start);
  const date_end = moment(end);
  const diffMonth = date_start.format("MMMM") === date_end.format("MMMM");
  let date_txt;
  if (diffMonth) {
    date_txt =
      date_start.format("Do") + " al " + date_end.format("Do MMMM YYYY");
  } else {
    date_txt =
      date_start.format("Do MMMM") + " al " + date_end.format("Do MMMM YYYY");
  }

  return date_txt;
}

export function printDate(date) {
  const date_start = moment(date);
  const date_txt = date_start.format("dddd Do, MMMM YYYY");

  return date_txt;
}

export function btn_loader(btn, text, block) {
  if (!btn && block) {
    return false;
  }
  if (block) {
    const btn_txt_current = btn.textContent; //save current button text
    const btn_width = btn.offsetWidth; // get width to avoid 'jumps'
    if (!text) {
      text = btn_txt_current;
    }
    btn.classList.add("disabled");
    btn.setAttribute("disabled", "disabled");
    btn.setAttribute("id", "currentrlyDisabled");
    btn.setAttribute("data-text", btn_txt_current);
    btn.textContent = text;
    btn.style.width = btn_width;
  } else {
    if (!btn) {
      btn = document.getElementById("currentrlyDisabled");
    }
    const past_text = btn.dataset.text;
    btn.textContent = past_text;
    btn.classList.remove("disabled");
    btn.removeAttribute("disabled");
    btn.removeAttribute("id");
  }
}

export function concatNames(names, by) {
  if (!names) return false;
  let name_arr = names.split(" ");
  return name_arr.join(by);
}

export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}
export function formatNumber(num, round = true) {
  if (round) {
    num = parseFloat(num);
    num = num.toFixed(2);
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function deliveryIcons(delivery) {
  return delivery === "pickup"
    ? "fa-fw fas fa-hand-point-up"
    : "fa-fw fas fa-shipping-fast";
}

export function renderDelivery(address) {
  if (!address || !address.distance) {
    return "";
  }
  if (Array.isArray(address.distance)) {
    return address.distance[0].distance.text;
  }
  return address.distance.distance.text || "";
}
export function paymentIcons(payment, is_payed, is_fake, colors = "text-primary") {
  if (is_fake) return `fas fa-skull-crossbones text-danger`;
  if (payment === "online") return `far fa-fw fa-credit-card ${colors}`;
  if (payment === "rappi") return `fas fa-mobile-alt ${colors}`;
  if (payment === "rewards") return `fas fa-hand-holding-heart ${colors}`;
  if (payment === "coppel") return `fas fa-comment-dollar ${colors}`;
  if (payment === "atrato") return `fas fa-money-check-alt ${colors}`;
  if (payment === "paypal") return `fab fa-cc-paypal ${colors}`;
  if (!is_payed) return `fa-fw fas fa-file-invoice`;

  return `fas fa-fw fa-file-invoice-dollar ${colors}`;
}

export function timeStampDate(date, type = "es-gb", fields = false) {
  if (!date) {
    return;
  }
  if (!fields) {
    fields = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
  }
  if (date === true) {
    return new Date().toLocaleDateString(type, fields);
  }
  if (typeof date.toDate === "undefined") {
    return new Date(date).toLocaleDateString(type, fields);
  }
  return new Date(date.toDate()).toLocaleDateString(type, fields);
}

export function joinByComma(text) {
  if (!text) {
    return "";
  }

  if (!Array.isArray(text)) {
    return text;
  }

  return text.join(", ");
}
