import React, { useEffect, useState } from "react";
import moment from "moment";

import axios from "../../../middleware/axios";
import { Toast } from "../components/alerts";
import { Header } from "../components/side_components";
import { db } from "../../../middleware/firebase";
import { joinByComma } from "../../../middleware/common-functions";
import { FormGroup, TextArea, Select, InputGroup } from "../components/forms";
import { ModalDefault } from "../components/modals";
import AutocompleteInput from "../components/autocomplete_input";

const promo_types = [
  {
    text: "Descuento Total | Monto",
    help: "Aplica un descuento al total de la compra",
    value: "total",
  },
  {
    text: "Descuento Total | Porcentaje",
    help: "Aplica un descuento al total de la compra",
    value: "percentage_all",
  },
  {
    text: "Descuento en productos seleccionados | Porcentaje",
    help: "Aplica un descuento a los productos seleccionados",
    value: "percentage",
  },
];

const productTypes = [
  {
    text: "Por Números de Parte",
    value: "products",
  },
  {
    text: "Por Marca",
    value: "brand",
  },
  {
    text: "Por Categorías",
    value: "cats",
  },
];

const CuponData = ({ cupon_data, disSelect, fetchCupons }) => {
  const [cupon, setCupon] = useState({ ...cupon_data });
  const [modal, setModal] = useState(false);
  const [toast, setToast] = useState({});
  let badge;
  let showProducts;

  useEffect(() => {
    cupon_data.valid = moment(cupon_data.valid).format("YYYY-MM-DD");
    setCupon(cupon_data);
  }, [cupon_data]);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 5000);
    }
  }, [toast]);

  switch (cupon.type) {
    case "percentage_all":
    case "percentage":
      badge = "%";
      break;

    default:
      badge = "$";
      break;
  }
  switch (cupon.type) {
    case "percentage":
      showProducts = true;
      break;

    default:
      showProducts = false;
      break;
  }

  const handleType = (ev) => {
    const new_cupon = { ...cupon };
    const type = ev.target.id;
    const value = ev.target.value;
    //alert(`${type} : ${value}`);
    if (type === "code") {
      new_cupon[type] = value.toLocaleUpperCase();
    } else {
      new_cupon[type] = value;
    }
    setCupon(new_cupon);
  };

  const saveCupon = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";
    const cupon_data = { ...cupon };
    cupon_data.code = cupon_data.code.toLocaleUpperCase();
    if (typeof cupon_data.idparts === "string" && cupon_data.idparts.trim()) {
      cupon_data.idparts = cupon_data.idparts.split(",").map((x) => x.trim());
    } else {
      cupon_data.idparts = [];
    }
    if (
      cupon_data.type === "percentage_all" ||
      cupon_data.type === "percentage"
    ) {
      cupon_data.value = cupon_data.value / 100;
    }
    try {
      const { data } = await axios.post("scriptsCartSavePromotion", {
        cupon: cupon_data,
      });
      button.classList.remove("disabled");
      button.textContent = "Guardar";
      if (data.error) {
        setToast({
          txt: data.error,
          type: "danger",
        });
        return;
      }
      if (cupon.id !== "new") {
      } else {
        setCupon(data);
      }
      fetchCupons();
    } catch (error) {
      console.log("cupon.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const toggleModal = async (ev) => {
    setModal(!modal);
  };

  const renderProductsAdd = () => {
    switch (cupon.addType) {
      case "brand":
        return (
          <AutocompleteInput
            key="marcas"
            multiple
            handleType={handleType}
            placeholder="Seleccionar marca"
            value={cupon.brands}
            type="brands"
            id="brands"
            label="Marca"
          />
        );
      case "cats":
        return (
          <AutocompleteInput
            key="categorias"
            multiple
            handleType={handleType}
            value={cupon.cats}
            placeholder="Seleccionar categoria"
            id="cats"
            type="categories"
            label="Categorías"
          />
        );
      default:
        return (
          <TextArea
            label="Productos Aplicados"
            help="Separar números de parte por coma. Ej: 542154, 545421"
            placeholder={
              showProducts
                ? "Pega aquí los números de parte separados por comas"
                : "Este tipo de descuento aplica a todo el carrito del cliente"
            }
            value={cupon.idparts ? joinByComma(cupon.idparts) : ""}
            handleType={handleType}
            id="idparts"
          />
        );
    }
  };

  const removeCupon = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    const collection = db.collection("promocodes").doc(cupon.id);
    button.textContent = "Eliminando...";

    try {
      await collection.delete();
      fetchCupons();
      disSelect();
    } catch (error) {
      console.log("cupon.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };
  console.log(cupon);
  return (
    <div className="page-sidebar bg-light p-3">
      <Header
        title={`Cupón: #${cupon.code ? cupon.code.toLocaleUpperCase() : ""}`}
      />
      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={cupon.code ? cupon.code.split(" ").join("") : ""}
            id="code"
            label="Cupon Name"
            input_classes="text-uppercase"
          />
          <Select
            value={cupon.type}
            id="type"
            label="Tipo de cupón"
            placeholder="Selecciona el tipo de promoción "
            options={promo_types}
            handleChange={handleType}
          />
          <FormGroup
            handleType={handleType}
            value={cupon.valid}
            id="valid"
            type="date"
            label="Válido hasta"
          />
          <FormGroup
            handleType={handleType}
            value={cupon.uses}
            id="uses"
            type="number"
            label="Número de Usos"
          />
          <FormGroup
            handleType={handleType}
            value={cupon.min_amount}
            id="min_amount"
            type="number"
            label="Monto mínimo de compra"
            help="Restringe el uso de este cupón a un monto mínimo | Opcional"
          />
          <InputGroup
            value={cupon.value}
            handleType={handleType}
            id="value"
            type="number"
            label="Valor"
            input_classes="form-control-lg"
            badge={badge}
          />
          {showProducts && (
            <Select
              value={cupon.addType || "products"}
              id="addType"
              label="Agregar productos por:"
              options={productTypes}
              handleChange={handleType}
            />
          )}
          {showProducts && renderProductsAdd()}
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button className="btn btn-block btn-success" onClick={saveCupon}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {cupon.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removeCupon}
        title={
          cupon.code ? `¿Borrar Código #${cupon.code.toLocaleUpperCase()}?` : ""
        }
        action_btn="danger"
        action_txt="Borrar Código"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
      <Toast
        show={toast.txt ? true : false}
        txt={toast.txt}
        type={toast.type || "success"}
        toggle={() => setToast(false)}
      />
    </div>
  );
};

export default CuponData;
