import React, { useEffect, useState } from "react";
import moment from "moment";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import { ListItem } from "../../common/lists";

import CuponSection from "./cupon";
import { useParams } from "react-router-dom";

const CuponsPage = () => {
  const [cupons, setCupons] = useState([]);
  const [selectedCupon, setSelectedCupon] = useState(false);
  const { past } = useParams();

  useEffect(() => {
    fetchCupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [past]);

  const fetchCupons = async () => {
    const cuponsRef = db.collection("promocodes");
    const start = new Date();
    const set_cupons = [];
    const operator = past ? "<" : ">";
    const snapshot = await cuponsRef.where("valid", operator, start).get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }

    snapshot.forEach((doc) => {
      const cupon_data = doc.data();
      cupon_data.id = doc.id;
      set_cupons.push(cupon_data);
    });
    setCupons(set_cupons);
  };

  const handleSelect = (ev) => {
    const cupon_id = ev.currentTarget.id;
    if (cupon_id === "new") {
      const new_cupon = {
        id: "new",
        valid: moment().add("1 month").format("YYYY-MM-DD"),
      };
      setSelectedCupon(new_cupon);
    } else {
      const selected = cupons.find((i) => i.id === cupon_id);
      const selected_copy = { ...selected };
      selected_copy.valid = selected.valid.toDate();
      console.log("index.js:52 | selected_copy.valid", selected_copy.valid);
      if (
        selected_copy.type === "percentage_all" ||
        selected_copy.type === "percentage"
      ) {
        selected_copy.value = selected_copy.value * 100;
      }
      setSelectedCupon(selected_copy);
    }
  };

  const disSelect = () => {
    setSelectedCupon(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Cupones" />
            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {cupons && cupons.length > 0 ? (
                  cupons.map((item) => {
                    const date = item.valid.toDate();
                    return (
                      <ListItem
                        title={item.code ? `#${item.code.toUpperCase()}` : "-"}
                        help={
                          "Vence: " +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        handleSelect={handleSelect}
                        key={`cupon_${item.id}`}
                        id={item.id}
                        letter={getLetter(item.type)}
                      />
                    );
                  })
                ) : (
                  <div className="text-center">
                    <p className=" py-3 text-muted">Sin Cupones Activos</p>
                    <button
                      type="button"
                      className="btn btn-primary "
                      title="Add new cupon"
                      id="new"
                      onClick={handleSelect}
                    >
                      Agregar Cupón Nuevo
                    </button>
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-floated position-fixed"
                title="Add new cupon"
                onClick={handleSelect}
                id="new"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>

          {selectedCupon && (
            <CuponSection
              cupon_data={selectedCupon}
              disSelect={disSelect}
              fetchCupons={fetchCupons}
            />
          )}
        </div>
      </div>
    </main>
  );
};

function getLetter(type) {
  switch (type) {
    case "percentage":
      return "%";

    default:
      return type ? type[0] : null;
  }
}

export default CuponsPage;
