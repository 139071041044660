import React, { Component } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line
import $ from "jquery";
// eslint-disable-next-line
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./store/actions";
import "./css/app.css";
import Header from "./components/header";
import Login from "./components/pages/login";
import Users from "./components/pages/users";
import Cupons from "./components/pages/cupons";
import Banners from "./components/pages/banners";
import Minibanners from "./components/pages/minibanners";
import Promotions from "./components/pages/promotions";
import BestSellers from "./components/pages/bestSellers";
import Featured from "./components/pages/featured_cats";
import Schedule from "./components/pages/schedule";

import Orders from "./components/pages/orders";
import Meses from "./components/pages/meses";
import Order from "./components/pages/order";
import Products from "./components/pages/products";
import ProductIcons from "./components/pages/icons";
import ProductsHistory from "./components/pages/history";
import { has_access } from "./middleware/page_permissions";

import Dashboard from "./components/dash";
import ScrollToTop from "./middleware/scrollTop";
import * as serviceWorker from "./serviceWorker";

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      isAuth: null,
    };
  }
  componentDidMount() {
    const isAuth = this.props.autoLogin();
    this.setState({ isAuth });
  }
  UNSAFE_componentWillReceiveProps() {
    setTimeout(() => {
      this.loginProps();
    }, 500);
  }

  loginProps = () => {
    if (this.props.user_id) {
      this.setState({ isAuth: true });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  renderLayout = () => {
    if (this.state.isAuth === null) {
      return null;
    }
    if (!this.state.isAuth) {
      return (
        <Switch>
          <Route path="/login" component={Login} />
          <Redirect to="/login" />
        </Switch>
      );
    } else {
      return (
        <React.Fragment>
          <Header />

          <Switch>
            {has_access(9) ? (
              <Route exact path="/users" component={Users} />
            ) : null}
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/banners/:past?" component={Banners} />
            <Route exact path="/minibanners" component={Minibanners} />
            <Route
              exact
              path="/promotions/:pagefilter?"
              component={Promotions}
            />
            <Route
              exact
              path="/best_sellers/:pagefilter?"
              component={BestSellers}
            />
            <Route
              exact
              path="/featured_cats/:featured_id?"
              component={Featured}
            />
            <Route exact path="/orders/:pagination?" component={Orders} />
            <Route exact path="/meses/:id?" component={Meses} />
            <Route exact path="/order/:user_id?/:order_id?" component={Order} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/icons" component={ProductIcons} />
            <Route exact path="/historial" component={ProductsHistory} />
            <Route path="/cupons/:past?" component={Cupons} />
            <Route exact path="/schedule" component={Schedule} />
            <Redirect to="/" />
          </Switch>
        </React.Fragment>
      );
    }
  };

  toggleNavMob = () => {
    const mobNav = document.getElementById("mob-menu");
    const backdrop = document.getElementById("aside-backdrop");
    mobNav.classList.remove("show");
    backdrop.classList.remove("show");
  };

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="app">{this.renderLayout()}</div>
        </ScrollToTop>
        <div
          className="aside-backdrop"
          id="aside-backdrop"
          onClick={this.toggleNavMob}
        ></div>
      </BrowserRouter>
    );
  }
}

const mapDispatchtoProps = (dispatch) => {
  return {
    autoLogin: () => dispatch(actions.authCheckState()),
  };
};

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
  };
};
export default connect(mapStateToProps, mapDispatchtoProps)(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
